@use '../abstract' as *;

/*----------------------------------------*/
/*  13. WHAT CSS START
/*----------------------------------------*/

.what{
    &__item{
        @include border-radius(6px);
        &:hover{
            & .what__thumb{
                & img{
                    @include transform(scale(1.1));
                }
            }
        }
    }
    &__thumb{}
    &__title{
        font-size: 30px;
        margin-bottom: 18px;
        @media #{$xs}{
            font-size: 18px;
        }
    }
    &__content{
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        z-index: 1;
        @media #{$xs}{
            width: 90%;
        }
    }
}